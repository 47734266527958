<template>
  <youtube :video-id="videoId" ref="youtube" :player-vars="playerVars" @playing="playing" style="width: 300px; height: 200px;"></youtube>
</template>

<script>
export default {
  name: "Placevideo",
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      playerVars: {
        autoplay: 0,
      },
    };
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
    videoId() {
      if (this.url) {
        const regex = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
        const r = this.url.match(regex);
        console.log("video ID");
        console.log(r);
        return r[2];
      }
      return "";
    },
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log("\\o/ we are watching!!!");
    },
  },
};
</script>

<style></style>
