<template>
  <v-card class="pa-10" flat>
    <v-card-title class="text-center justify-center py-6">
      <h6 class="font-weight-bold text-h2">
        Help Page
      </h6>
    </v-card-title>
    <v-tabs color="deep-purple accent-4" centered>
      <v-tab v-for="(asset, count) in assets" :key="count">{{ asset.tag }}</v-tab>

      <v-tab-item v-for="(asset, count) in assets" :key="count">
        <v-row align="center" no-gutters>
          <v-col v-for="(video, index) in asset.content" :key="index" cols="12" sm="4">
            <v-card :loading="loading" class="mx-auto my-12" max-width="360" flat>
              <template slot="progress">
                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
              </template>
              <div>
                <youtube-video :url="`${video.url}`"></youtube-video>
              </div>
              <!-- <video controls height="250" width="317">
                <source :src="`${media_path}${video.url}`" type="video/mp4" />
              </video> -->
              <v-card-title>{{ video.title }}</v-card-title>
              <v-card-text>
                <div>{{ video.description }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import YoutubeVideo from "@/components/YoutubeVideo.vue";

export default {
  components: { YoutubeVideo },
  data() {
    return {
      loading: false,
      selection: 1,
      assets: [
        {
          tag: "Misc",
          content: [
            {
              url: "https://youtu.be/UJnQ4V1dEws",
              title: "Alerts",
              description: "Help with alerts",
            },
            {
              url: "https://youtu.be/Q-1Ji9OPZUU",
              title: "Dashboard",
              description: "Help with account dashboard",
            },
            {
              url: "https://youtu.be/NSvsXcQdvMs",
              title: "Missions",
              description: "Help with missions",
            },

            {
              url: "https://youtu.be/GJTpK39qqwg",
              title: "Settings",
              description: "Help with settings",
            },
          ],
        },
        {
          tag: "Account",
          content: [
            {
              url: "https://youtu.be/1hsgij5swvk",
              title: "Create An Account",
              description: "Help with creating an account",
            },
            {
              url: "https://youtu.be/M54Vt1pIE9A",
              title: "Login",
              description: "Help with Login",
            },
            {
              url: "https://youtu.be/JZB28ngbF90",
              title: "Account Settings",
              description: "Help with account settings",
            },
          ],
        },
        {
          tag: "Asset",
          content: [
            {
              url: "https://youtu.be/IVJWe0sr78s",
              title: "Asset Battery",
              description: "Help with asset battery",
            },
            {
              url: "https://youtu.be/RrMBFiDEzKY",
              title: "Camera",
              description: "Help with asset camera",
            },
            {
              url: "https://youtu.be/lRGdeNVd-LA",
              title: "Aircraft",
              description: "Help with asset aircraft",
            },
            {
              url: "https://youtu.be/6faqAMG0_9M",
              title: "Radio",
              description: "Help with asset radio",
            },
          ],
        },
      ],
    };
  },

  computed: {
    media_path() {
      return this.$MEDIA;
    },
  },
};
</script>
